/*===========================
     01.COMMON CSS 
===========================*/
@import url("https://fonts.googleapis.com/css2?family=Saira:wght@300;400;500;600;700;800&display=swap");
/* Add your common CSS styles here */

/*Be Our Expert Area*/
.be-our-expert-area {
  background-color: #f6f6f6;
  padding: 80px 0;
}
.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  background-color: white;
  padding: 10px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  font-size: 16px;
  color: #272727;
}
.react-tagsinput-tag {
  background-color: #88c5ad;
  border-radius: 2px;
  border: 1px solid #a5d24a;
  color: #191a1c;
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}
.form-submit {
  display: inline-block;
  background-color: #000000;
  border-radius: 5px;
  min-width: 230px;
  font-weight: 700;
  font-size: 17px;
  text-align: center;
  color: #ffffff;
  padding: 15px 30px;
  border: 1px solid #000000;
  overflow: hidden;
  transition: all 0.8s ease-out 0s;
  vertical-align: top;
  cursor: pointer;
}
.form-submit:hover {
  background-color: #75dab4;
  border: 1px solid #75dab4;
}

.be-our-expert-content {
  display: flex;
  flex-direction: column; /* Change to column layout for mobile */
  justify-content: space-between;
}

.be-our-expert-text {
  flex: 1;
  padding-right: 22px;
}

.expert-title {
  font-weight: bold;
  font-size: 36px;
  color: #17161a;
  margin-bottom: 20px;
}

.expert-description {
  font-size: 16px;
  color: #272727;
  line-height: 1.8;
  margin-bottom: 30px;
}

.why-expert-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.why-expert-list {
  list-style-type: disc;
  margin-left: 20px;
}

.be-our-expert-form {
  flex: 1;
  margin-top: 20px; /* Add margin for separation */
}

.form-title {
  font-size: 24px;
  font-weight: bold;
  color: #17161a;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  color: #17161a;
  margin-bottom: 8px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  font-size: 16px;
  color: #272727;
}

/* Rest of your CSS remains the same */

/* Media query for mobile */
@media (max-width: 768px) {
  .be-our-expert-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .be-our-expert-text {
    padding-right: 0;
  }
}

/* Existing CSS ... */

/* New Styles for Digital Shifts Theme */
.ds-btn {
  background-color: #000000; /* Existing theme primary color */
  color: #ffffff;
  font-size: 16px;
  padding: 8px 10px;
  border: none;
  border-radius: 5px; /* Matching existing border-radius */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.2s, transform 0.1s;
  font-family: "Saira", sans-serif; /* Matching existing font-family */
}

.ds-btn:active {
  transform: scale(0.98);
}

.ds-form-group {
  margin-bottom: 20px; /* Consistent margin with existing form groups */
}

.ds-image-preview {
  width: 100px; /* Preview width */
  height: 100px; /* Preview height */
  border-radius: 5px; /* Matching existing border-radius */
  overflow: hidden;
  border: 1px solid #e1e1e1; /* Using existing border style */
  margin-top: 16px; /* Space above the image preview */
}

.ds-image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Media queries remain the same ... */
.success-message {
  color: #fff;
  border: 1px solid #f6f6f6;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  position: sticky;
  z-index: 3;
  background: #88c5ad;
  width: 370px;
}

.error-message {
  color: red;
  border: 1px solid red;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  position: sticky;
  z-index: 3;
  background: #f6f6f6;
  width: 370px; /* set the width to 50% of the viewport width */
}

.expertForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
