.cta-section {
    background-color: #191a1c;
    padding: 60px 0;
}

.call-to-action-btn {
    background-color: #75dab4;
    border-radius: 10px;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    -webkit-transition: all 0.5s ease-out 0s;
    transition: all 0.5s ease-out 0s;
    margin: 0 auto;
    padding: 14px 26px;
}

.call-to-action-btn:hover {
    color: #75dab4;
    background-color: #fff;
}