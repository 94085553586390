.root {
  background-color: rgb(98 145 121);
  display: flex;
}

.close_btn {
  color: #f0ffffa1;
  cursor: pointer;
  font-size: 1.5em;
  position: absolute;
  right: 10px;
  top: 10px;
}

.logo {
  align-items: center;
  align-self: center;
  background: #fff;
  border-radius: 50%;
  color: cadetblue;
  display: flex;
  font-size: 2em;
  height: 45px;
  margin: 5px;
  justify-content: center;
  padding: 10px;
  width: 45px;
}

.texts {
  color: rgb(255, 255, 255);
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.texts_h1 {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  display: block;
}

.texts_span {
  font-size: 13px;
  line-height: 18px;
  margin-top: 4px;
}
