.ant-card-body {
  padding: 16px;
  background-color: #ffffff;
}
.cardbody .ant-card-body {
  padding-left: 0px;
  padding-right: 0px;
}
.mb-24 {
  margin-bottom: 24px;
}
.ant-card-head-wrapper {
  min-height: 72px;
}
/* Custom styles for the dashboard */
.ant-card-head {
  background-color: #4a715b;
  color: #f5f5f5;
}

.ant-typography {
  color: #191a1c;
}
.ant-card-body {
  padding: 16px;
  background-color: #ffffff;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li svg path {
  color: #bfbfbf;
}

.cardbody .ant-card-body {
  padding-left: 0px;
  padding-right: 0px;
}

.ant-card.criclebox .project-ant {
  padding-left: 24px;
  padding-right: 24px;
}

.ant-card.criclebox table th {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 14px;
  padding-bottom: 14px;
}
.ant-card.criclebox {
  box-shadow: 0px 20px 27px #00000020;
  border-radius: 12px;
}

.number span {
  font-weight: 600;
  color: #8c8c8c;
  font-size: 14px;
}
.number h3 {
  font-weight: 700;
  margin-bottom: 0px;
  font-size: 30px;
}

.number h3 small {
  font-weight: 600;
  font-size: 14px;
}
.icon-box {
  width: 48px;
  height: 48px;
  text-align: center;
  background: #5fcba0;
  color: #fff;
  border-radius: 0.5rem;
  margin-left: auto;
  line-height: 55px;
}

.icon-box span {
  color: #fff;
  font-size: 24px;
}
