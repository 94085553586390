/* Sidebar.css */
/* Hide sidebar on mobile screens */
@media screen and (max-width: 768px) {
  .sidebar-container {
    display: none;
  }
}
.site-layout .site-layout-background {
  background: #191a1c;
}

.admin-logo {
  display: flex;
  align-items: center;
  padding: 16px;
}

.admin-logo-text {
  margin-left: 8px;
}

.admin-logout {
  position: absolute;
  bottom: 16px;
  width: 65%;
  left: 15%;
}
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #88c4ac;
  border-color: #88c4ac;
}
.ant-menu-title-content {
  color: white;
}
.ant-layout-sider-collapsed .ant-menu-inline-collapsed .ant-menu-item-selected {
  background-color: #75dab4 !important;
}
.ant-table-body {
  max-height: none !important; /* Remove max-height restriction */
}

.ant-layout-sider {
  background-color: #191a1c;
  color: #e4e4e4;
}

.ant-menu-item {
  color: #e4e4e4;
}
.ant-menu .ant-menu-item-selected {
  background-color: #e4e4e4;
  color: #191a1c;
}
.ant-menu .ant-menu-item-selected .ant-menu-title-content {
  color: #191a1c;
}
.ant-menu .ant-menu-item-active .ant-menu-item-icon {
  color: #88c4ac;
}

.ant-menu .ant-menu-item-active .ant-menu-title-content {
  color: #88c4ac;
}

.ant-avatar {
  margin-right: 8px;
}

.ant-avatar.ant-avatar-square.ant-avatar-icon {
  background-color: #75dab4;
  color: #e4e4e4;
}

.ant-spin-dot-item {
  background-color: #88c4ac !important;
}
