.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #42876c;
}

.card {
  width: 300px;
  padding: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

h4 {
  text-align: center;
  margin-bottom: 16px;
  color: #397d63;
}

/* Style the text fields */
.MuiTextField-root {
  margin: 12px 0;
}

/* Style the login button */
.MuiButton-root {
  width: 100%;
  margin-top: 16px;
}

.MuiButton-root:hover {
  background-color: #1284d6;
}
